
  import { Component, Vue, Emit, Prop} from 'vue-property-decorator'
  @Component({
    name:"detailAccountScreenshot",
  })
  export default class detailAccountScreenshot extends Vue {
    @Prop(Boolean) isShow?: any
    @Prop(Array) imageBoxArea?: any
    private options:any={
      "navbar": false,
      hidden:()=> {
        // this.isShow=false
        (this.$parent as any).showBox = false
        let body:any = document.querySelectorAll('body') as NodeListOf<HTMLElement>
        body[0].setAttribute('style', 'overflow:auto')
      }
    }
    mounted(){
    }
    @Emit()
    hiddens(){
      this.isShow=false
      let body:any = document.querySelectorAll('body') as NodeListOf<HTMLElement>
      body[0].setAttribute('style', 'overflow:auto')
    }
    @Emit()
    //展示
    show (index:number) {
      const viewer = (this.$refs.viewer as any).$viewer
      viewer.view(index)
      viewer.show()
    }


  }


  import { Component, Vue, Emit, Prop} from 'vue-property-decorator'
  @Component({
    name:"gameAccountDetail",
  })
  export default class gameAccountDetail extends Vue {
    @Prop(Object) goodsInfo_?: any
    mounted(){
      console.log(this.goodsInfo_,'===========goodsInfo_=======啦啦啦啦啦=')
      // this.getGoodsDetail()
    }
    @Emit()
    getGoodsDetail(){
      if (this.goodsInfo_.goodsShowTileList) {
        // console.log(this.goodsInfo_.goodsShowTileList.filter((v:any) => {
        //   if (v.type != "" && v.type!='isNoZero' && v.type!='noDetail'){
        //     return true
        //   }
        // }),'===goodsShowTileList==哈哈哈1=')
        let flag:any=this.goodsInfo_.goodsShowTileList.filter((v:any) => {
          // console.log(v.type,v.key,this.goodsInfo_[v.key],'==========v==========')
          if ((v.type != "" && v.type!='noDetail')){
            return true
          }
        })
        let flag2:any=flag.filter((v:any) => {
          // console.log(v.type,v.key,this.goodsInfo_[v.key],'==========v==========')
          if ((v.type=='isNoZero' && !this.goodsInfo_[v.key])){
            return false
          } else {
            return true
          }
        })
        console.log(flag2,'===goodsShowTileList==哈哈哈3=')
        return flag2
      } else {
        return []
      }
    }
    @Emit()
    getGoodsDetailValue(key:string){
      let flagArray:any=key.split(',')
      let flagValue:string=''
      for (let i:number=0;i<flagArray.length;i++){
        if (this.goodsInfo_ && this.goodsInfo_[flagArray[i]]){
          if (flagValue) {
            flagValue=`${flagValue}-${this.goodsInfo_[flagArray[i]]}`
          } else {
            flagValue=this.goodsInfo_[flagArray[i]]
          }
        }
      }
      return flagValue
    }
    @Emit()
    //一元=？金币
    oneRMBToTenThousand(item:any) {
      let flag=item.bigTitle
      let startFlag:number=0
      let endFlag:number=0
      console.log(String(flag).indexOf('='))
      if (String(flag).indexOf('=')>-1) {
        endFlag=String(flag).indexOf('=')
        // console.log(flag.substring(endFlag-4,endFlag-3),flag.substring(endFlag-3,endFlag-2),'哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈')
        if (flag.substring(endFlag-4,endFlag-3)>='0' && flag.substring(endFlag-4,endFlag-3)<='9') {
          // console.log(item.oneNumber,item.price,'大大大大大大大大大大大大大大大大大大')
          return flag.substring(endFlag-3,endFlag)
        } else if (flag.substring(endFlag-3,endFlag-2)>='0' && flag.substring(endFlag-3,endFlag-2)<='9') {
          // console.log(item.oneNumber,item.price,'中中中中中中中中中中中中中中中中中中中')
          return flag.substring(endFlag-2,endFlag)
        }
      }
      // return parseFloat(item.number / item.price) ? parseFloat(item.number / item.price).toFixed(2)+flag : "0.00"+flag
    }
  }



export default {
        // 奇数个【security_level】放在最后，偶数个【security_level】放在倒数第二个

        "dnf":"地下城与勇士",
        1:{
          1: {
            // qq_level:"QQ等级".
            gameName: {
              name: "游戏区服",
              type: 'gamAreaServer',
            },
            game_jobs_name: {
              name: "角色职业",
              type: 'account',
            },
            goods_job_list: {
              name: "其他职业",
              type: 'account',
            },
            role_level: {
              name: "角色等级",
              type: 'account',
            },
            crossName: {
              name: "战区",
              type: 'account',
            },
            role_sex: {
              name: "角色性别",
              type: 'account',
            },
            is_friend: {
              name: "QQ好友",
              type: 'account',
            },
            security_level: {
              name: "安全级别",
              type: 'security_level',
            },
          },
          4: {
            // qq_level:"QQ等级".
            gameName: {
              name: "游戏区服",
              type: 'gamAreaServer',
            },
            game_jobs_name: {
              name: "角色职业",
              type: 'account',
            },
            goods_job_list: {
              name: "其他职业",
              type: 'goods_job_list',
            },
            role_level: {
              name: "角色等级",
              type: 'role_level',
            },
            crossName: {
              name: "战区",
              type: 'account',
            },
            role_sex: {
              name: "角色性别",
              type: 'account',
            },
            is_friend: {
              name: "QQ好友",
              type: 'account',
            },
            security_level: {
              name: "安全级别",
              type: 'security_level',
            },
          },
        },
        "wzry":"王者荣耀",
        3:{
          1:{
            gameName: {
              name: "游戏区服",
              type: 'gamAreaServer',
            },
            GameJobs_num:{
              name:"英雄数量",
              type:'account',
            },
            skin_num:{
              name:"皮肤数量",
              type:'account',
            },
            ranking:{
              name:"段位等级",
              type:'account',
            },
            // special_hero:{
            //   name:"特殊英雄",
            //   type:'account',
            // },
            account_type:{
              name:"账号类型",
              type:'account',
            },
            security_level:{
              name:"安全级别",
              type:'security_level',
            },
            rune:{
              name:"五级铭文",
              type:'account',
            },
          }
        },
        "lol":"英雄联盟",
        4:{
          1:{
            gameName: {
              name: "游戏区服",
              type: 'gamAreaServer',
            },
            GameJobs_num:{
              name:"英雄数量",
              type:'account',
            },
            skin_num:{
              name:"皮肤数量",
              type:'account',
            },
            single_ranking:{
              name:"单双排段位",
              type:'account',
            },
            flexible_ranking:{
              name:"灵活组排",
              type:'account',
            },
            role_level:{
              name:"游戏等级",
              type:'role_level',
            },
            security_level:{
              name:"安全级别",
              type:'security_level',
            },
          }
        },
        "cf":"穿越火线",
        5:{
          1:{
            gameName:{
              name:"游戏区服",
              type:'gamAreaServer',
            },
            hero_weapon:{
              name:"英雄武器数量",
              type:'account',
            },
            ranking:{
              name:"段位等级",
              type:'account',
            },
            match_qualification:{
              name:"排位赛资格",
              type:'account',
            },
            security_level:{
              name:"安全级别",
              type:'security_level',
            },
          }
        },
        "jxqys":"剑侠情缘三",
        8:{
          1:{
            gameName:{
              name:"游戏区服",
              type:'gamAreaServer',
            },
            game_jobs_name:{
              name:"角色职业",
              type:'account',
            },
            role_level:{
              name:"角色等级",
              type:'role_level',
            },
            shape:{
              name:"角色体型",
              type:'account',
            },
            talent:{
              name:"角色天赋",
              type:'account',
            },
            camp:{
              name:"角色阵营",
              type:'account',
            },
            security_level:{
              name:"安全级别",
              type:'security_level',
            },
            bind_service:{
              name:"增值服务",
              type:'account',
            },
          }
        },
        "yys":"阴阳师",
        9:{
          1:{
            gameName:{
              name:"游戏区服",
              type:'gamAreaServer',
            },
            role_level:{
              name:"角色等级",
              type:'role_level',
            },
            crossName:{
              name:"战区",
              type:'account',
            },
            role_sex:{
              name:"角色性别",
              type:'account',
            },
            is_ban:{
              name:"处罚记录",
              type:'account',
            },
            security_level:{
              name:"安全级别",
              type:'security_level',
            },
          }
        },
        "mygwzd":"命运冠位指定",
        10:{
          1:{
            gameName:{
              name:"游戏区服",
              type:'gamAreaServer',
            },
            role_level:{
              name:"角色等级",
              type:'role_level',
            },
            crossName:{
              name:"战区",
              type:'account',
            },
            role_sex:{
              name:"角色性别",
              type:'account',
            },
            is_ban:{
              name:"处罚记录",
              type:'account',
            },
            security_level:{
              name:"安全级别",
              type:'security_level',
            },
          }
        },
        "yhzt":"永恒之塔",
        11:{
          1:{
            gameName:{
              name:"游戏区服",
              type:'gamAreaServer',
            },
            game_jobs_name:{
              name:"角色职业",
              type:'account',
            },
            clan:{
              name:"角色阵营",
              type:'account',
            },
            role_sex:{
              name:"角色性别",
              type:'account',
            },
            role_level:{
              name:"角色等级",
              type:'role_level',
            },
            bind_service:{
              name:"增值服务",
              type:'account',
            },
            security_level:{
              name:"安全级别",
              type:'security_level',
            },
          }
        },
        "tymyd":"天涯明月刀",
        12:{
          1:{
            gameName:{
              name:"游戏区服",
              type:'gamAreaServer',
            },
            game_jobs_name:{
              name:"角色职业",
              type:'account',
            },
            role_level:{
              name:"角色等级",
              type:'role_level',
            },
            role_sex:{
              name:"角色性别",
              type:'account',
            },
            security_level:{
              name:"安全级别",
              type:'security_level',
            },
            identity:{
              name:"身份",
              type:'account',
            },
          },
          11:{
            gameName:{
              name:"游戏区服",
              type:'gamAreaServer',
            },
            character:{
              name:"性格",
              type:'account',
            },
            bb:{
              name:"",
              type:'account',
            },
            tag_title:{
              name:"TAG标签",
              type:'tag_title',
            },
          }
        },
        "xtlbb":"新天龙八部",
        13:{
          1:{
            gameName:{
              name:"游戏区服",
              type:'gamAreaServer',
            },
            game_jobs_name:{
              name:"角色职业",
              type:'account',
            },
            role_level:{
              name:"角色等级",
              type:'role_level',
            },
            role_sex:{
              name:"角色性别",
              type:'account',
            },
            gemstone_level:{
              name:"宝石级别",
              type:'account',
            },
            equip_score:{
              name:"装备评分",
              type:'account',
            },
            practice_score:{
              name:"修炼评分",
              type:'account',
            },
            method_score:{
              name:"心法评分",
              type:'account',
            },
            security_level:{
              name:"安全级别",
              type:'security_level',
            },
            upgrade_score:{
              name:"进阶评分",
              type:'account',
            },
          }
        },
        "wow":"魔兽世界",
        14:{
          1:{
            gameName:{
              name:"游戏区服",
              type:'gamAreaServer',
            },
            camp:{
              name:"游戏阵营",
              type:'account',
            },
            game_jobs_name:{
              name:"角色职业",
              type:'account',
            },
            clan:{
              name:"种族",
              type:'account',
            },
            role_level:{
              name:"角色等级",
              type:'role_level',
            },
            role_sex:{
              name:"角色性别",
              type:'account',
            },
            security_level:{
              name:"安全级别",
              type:'security_level',
            },
          }
        },
        "lzhx":"龙族幻想",
        15:{
          1:{
            gameName:{
              name:"游戏区服",
              type:'gamAreaServer',
            },
            game_jobs_name:{
              name:"角色职业",
              type:'account',
            },
            shape:{
              name:"角色体型",
              type:'account',
            },
            num:{
              name:"库存",
              type:'account',
            },
            security_level:{
              name:"安全级别",
              type:'security_level',
            },
          }
        },
        "yqlzy":"一起来捉妖",
        16:{
          1:{
            gameName:{
              name:"游戏区服",
              type:'gamAreaServer',
            },
            aa:{
              name:"",
              type:'account',
            },
            num:{
              name:"库存",
              type:'account',
            },
            bb:{
              name:"",
              type:'account',
            },
            security_level:{
              name:"安全级别",
              type:'security_level',
            },
          }
        },
        "wowhj":"魔兽世界怀旧服",
        17:{
          1:{
            gameName:{
              name:"游戏区服",
              type:'gamAreaServer',
            },
            camp:{
              name:"游戏阵营",
              type:'account',
            },
            game_jobs_name:{
              name:"角色职业",
              type:'account',
            },
            clan:{
              name:"种族",
              type:'account',
            },
            role_level:{
              name:"角色等级",
              type:'role_level',
            },
            role_sex:{
              name:"角色性别",
              type:'account',
            },
            is_ban:{
              name:"封号记录",
              type:'is_no_zero',//如果不存在默认值为0，则不显示无
            },
            original_master:{
              name:"原号主",
              type:'is_no_zero',//如果不存在默认值为0，则不显示无
            },
            is_ids_img:{
              name:"提供证件",
              type:'is_no_zero',//如果不存在默认值为0，则不显示无
            },
            is_turn_server:{
              name:"能否转服",
              type:'is_no_zero',//如果不存在默认值为0，则不显示无
            },
            email_is_sell:{
              name:"邮箱出售",
              type:'is_no_zero',//如果不存在默认值为0，则不显示无
            },
            // security_level:{
            //   name:"安全级别",
            //   type:'security_level',
            // },
          },
          4:{
            area_server:{
              name:"游戏区服",
              type:'gamAreaServer',
            },
            camp:{
              name:"游戏阵营",
              type:'account',
            },
            game_jobs:{
              name:"角色职业",
              type:'game_jobs',
            },
            clan:{
              name:"种族",
              type:'account',
            },
            role_level:{
              name:"角色等级",
              type:'role_level',
            },
            role_sex:{
              name:"角色性别",
              type:'account',
            },
            is_ban:{
              name:"封号记录",
              type:'is_no_zero',
            },
            original_master:{
              name:"原号主",
              type:'is_no_zero',
            },
            is_ids_img:{
              name:"提供证件",
              type:'is_no_zero',
            },
            is_turn_server:{
              name:"能否转服",
              type:'is_no_zero',
            },
            email_is_sell:{
              name:"邮箱出售",
              type:'is_no_zero',
            },
            num:{
              name:"库存",
              type:'noDetail',
            },
          }
        },
        "zyq":"资源群",
        18:{
          8:{
            gameName:{
              name:"社群地区",
              type:'areaServer',
            },
            industry:{
              name:"社区行业",
              type:'account',
            },
            bb:{
              name:"",
              type:'account',
            },
            tag_title:{
              name:"TAG标签",
              type:'tag_title',
            },
          },
          9:{
            gameName:{
              name:"社群地区",
              type:'areaServer',
            },
            industry:{
              name:"社区行业",
              type:'account',
            },
            bb:{
              name:"",
              type:'account',
            },
            tag_title:{
              name:"TAG标签",
              type:'tag_title',
            },
          },
        },
        "lscs":"炉石传说",
        19:{
          1:{
            gameName:{
              name:"游戏区服",
              type:'gamAreaServer',
            },
            account_type:{
              name:"账号类型",
              type:'account',
            },
            aa:{
              name:"",
              type:'account',
            },
            security_level:{
              name:"安全级别",
              type:'security_level',
            },
          }
        },
        "qqsg":"QQ三国",
        20:{
          1:{
            gameName:{
              name:"游戏区服",
              type:'gamAreaServer',
            },
            game_jobs_name:{
              name:"角色职业",
              type:'account',
            },
            country:{
              name:"国家",
              type:'account',
            },
            concurrently_job:{
              name:"副职业",
              type:'account',
            },
            role_sex:{
              name:"角色性别",
              type:'account',
            },
            security_level:{
              name:"安全级别",
              type:'security_level',
            },
          },
          6:{
            gameName:{
              name:"游戏区服",
              type:'gamAreaServer',
            },
            num:{
              name:"库存",
              type:'account',
            },
            aa:{
              name:"",
              type:'account',
            },
            security_level:{
              name:"安全级别",
              type:'security_level',
            },
          }
        },
        "hpjy":"和平精英",
        56:{
          1:{
            gameName:{
              name:"游戏区服",
              type:'gamAreaServer',
            },
            security_level:{
              name:"安全级别",
              type:'security_level',
            },
            skin_list:{
              name:"外观套装",
              type:'noDetail',
            },
            equip:{
              name:"枪械皮肤",
              type:'noDetail',
            },
            mounts:{
              name:"坐骑载具",
              type:'noDetail',
            },
          },
        },
        "dwrg":"第五人格",
        60:{
          1:{
            gameName:{
              name:"游戏区服",
              type:'gamAreaServer',
            },
            security_level:{
              name:"安全级别",
              type:'security_level',
            },
            universally:{
              name:"求生者",
              type:'noDetail', //详情页不显示
            },
            currency:{
              name:"监管者",
              type:'noDetail', //详情页不显示
            },
            skin_list:{
              name:"热门紫皮",
              type:'noDetail', //详情页不显示
            },
            equip:{
              name:"挂件",
              type:'noDetail', //详情页不显示
            },
            other:{
              name:"其他",
              type:'noDetail', //详情页不显示
            },
          },
        },
        "qzwz":"枪战王者",
        64:{
          1:{
            gameName:{
              name:"游戏区服",
              type:'gamAreaServer',
            },
            security_level:{
              name:"安全级别",
              type:'security_level',
            },
          },
        },

  // }
}

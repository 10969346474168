export default {
  1: [
    {
      "id":1,
      "title": "找回包赔",
      "tip": "该商品享受找回包赔服务，不转手永久包赔",
      "fontColor":"#17B8F4",
      "bgColor":"#E1F7FF",
      "borderColor":"#89DFFF",
      "url":"https://oss1.hk1686.com/img/036/zypa/2022/06/10/1654843774738",
      "url2":"https://oss1.hk1686.com/img/036/zypa/2022/06/10/1654843864412"

    },
    {
      "id":2,
      "title": "官方自营",
      "tip": "该商品为官方自营商品，请放心购买",
      "fontColor":"#14C071",
      "bgColor":"#E4F9EF",
      "borderColor":"#6CE7AE",
      "url":"https://oss1.hk1686.com/img/036/zypa/2022/06/10/1654844014185",
      "url2":"https://oss1.hk1686.com/img/036/zypa/2022/06/10/1654844024642"
    },
    {
      "id":3,
      "title": "不玩回收",
      "tip": "购买后可以找平台回收，享受优质估价",
      "fontColor":"#FF9212",
      "bgColor":"#FFF3E1",
      "borderColor":"#FECC80",
      "url":"https://oss1.hk1686.com/img/036/zypa/2022/06/10/1654844048204",
      "url2":"https://oss1.hk1686.com/img/036/zypa/2022/06/10/1654844059589"
    },
    {
      "id":4,
      "title": "闪电发货",
      "tip": "一键下单，快速发货，闪电服务",
      "fontColor":"#59BE16",
      "bgColor":"#F0FFE6",
      "borderColor":"#AEE589",
      "url":"https://oss1.hk1686.com/img/036/zypa/2022/06/10/1654843980056",
      "url2":"https://oss1.hk1686.com/img/036/zypa/2022/06/10/1654843990784"
    },
    {
      "id":5,
      "title": "签署合同",
      "tip": "购买该商品后签署买卖合同，充分保障买家安全",
      "fontColor":"#A28D4C",
      "bgColor":"#FFF7DE",
      "borderColor":"#A28D4C",
      "url":"https://oss1.hk1686.com/img/036/zypa/2022/06/10/1654844154127",
      "url2":"https://oss1.hk1686.com/img/036/zypa/2022/06/10/1654844168960"
    },
    {
      "id":6,
      "title": "专属客服",
      "tip": "享受专属客服全程跟进，贴心服务",
      "fontColor":"#14C071",
      "bgColor":"#E4F9EF",
      "borderColor":"#6CE7AE",
      "url":"https://oss1.hk1686.com/img/036/zypa/2022/06/10/1654843929577",
      "url2":"https://oss1.hk1686.com/img/036/zypa/2022/06/10/1654843957283"
    },
    {
      "id":7,
      "title": "主播担保",
      "tip": "该商品由主播担保，信誉保障，放心购买",
      "fontColor":"#F02929",
      "bgColor":"#FFEFEF",
      "borderColor":"#EFB1B1",
      "url":"https://oss1.hk1686.com/img/036/zypa/2022/06/10/1654843774738",
      "url2":"https://oss1.hk1686.com/img/036/zypa/2022/06/10/1654843864412"
    },
    {
      "id":8,
      "title": "平台承保",
      "tip": "平台已为该商品办理包赔服务，安全保障",
      "fontColor":"#F02929",
      "bgColor":"#FFEFEF",
      "borderColor":"#EFB1B1",
      "url":"https://oss1.hk1686.com/img/036/zypa/2022/06/10/1654843929577",
      "url2":"https://oss1.hk1686.com/img/036/zypa/2022/06/10/1654843957283"
    },
    {
      "id":9,
      "title": "实名认证",
      "tip": "平台已严审卖家资料，真实有效，放心购买",
      "fontColor":"#FF9212",
      "bgColor":"#FFF3E1",
      "borderColor":"#FECC80",
      "url":"https://oss1.hk1686.com/img/036/zypa/2022/06/10/1654844077908",
      "url2":"https://oss1.hk1686.com/img/036/zypa/2022/06/10/1654844107655"
    },
    {
      "id":10,
      "title": "高性价比",
      "tip": "享受专属客服全程跟进，贴心服务",
      "fontColor":"#FF9212",
      "bgColor":"#FFF3E1",
      "borderColor":"#FECC80",
      "url":"https://oss1.hk1686.com/img/036/zypa/2022/06/10/1654844077908",
      "url2":"https://oss1.hk1686.com/img/036/zypa/2022/06/10/1654844107655"
    }
  ],

}



  import { Component, Vue, Emit, Prop, Watch } from 'vue-property-decorator'
  import {bargainingHttp} from "~/api/goods";
  @Component({
    name:"dcModal6",
    components: {
      newImg: require('~/components/comm/newImg/index.vue').default,
    },
  })
  export default class dcModal extends Vue {
    @Prop(Object) goodsInfo_?: any
    @Prop(Number) ensureRatio_?: any
    @Prop(Number) ensureMinPirce_?: any
    @Prop(Boolean) value?: false // 是否显示
    @Prop(String) left?: '45%' // 弹框位置
    @Prop(String) top?: '25%' // 弹框位置
    @Prop(String) width?: '400px' // 弹框大小
    public eventMsgTost:any
    public eventNotifyTost:any
    public errorToastFn:any
    public checkForm:any
    public priceRegular:any
    public changePrice:any
    private msgTxt:any = {
      expectPrice:'您的出价不能为空',
    }
    private expectPrice:string=''
    @Emit('bindSend') send(msg: any){}; // send 处理给父组件传值的逻辑

    mounted(){
      // console.log('==============')
    }
    @Watch("expectPrice")
    getExpectPrice(newVal: any) {
      if (newVal == '') {
        this.msgTxt['expectPrice'] = '您的出价不能为空'
      } else if (!this.priceRegular.test(newVal)) {
        this.msgTxt['expectPrice'] = '出价格式错误'
      } else {
        this.msgTxt['expectPrice'] = ''
      }
      this.expectPrice=newVal
    }
    //子组件控制是否显示
    @Emit()
    async closeDcModal(v:any){
      console.log(v,'哈哈哈哈哈哈哈哈哈')
      if (v && v.type=='ok') { //关闭时默认为click
        /*调用接口*/
        let info:Array<any>=[{key:"expectPrice",value:this.msgTxt['expectPrice']?'':this.expectPrice,tip:this.msgTxt['expectPrice']}]
        if (this.checkForm(info)) {
          let price:any=this.changePrice(this.goodsInfo_.price,this.goodsInfo_.addPrice,this.goodsInfo_.goodsSn)
          if (Number(this.expectPrice)>Number(price)) {
            this.eventMsgTost("砍价金额不能高于原价！","warning")
            return
          } else if (Number(price)*0.7>Number(this.expectPrice)) {
            this.eventMsgTost("您的出价低于卖家预期，请提高出价金额重新出价！", "warning")
            return
          }
        //   return
          try {
            let {data}:any=await bargainingHttp({method:'POST',data:{goodsId:this.goodsInfo_.id,buyBargaPrice:this.expectPrice}})
            if (this.errorToastFn(data)) {
              this.eventMsgTost("还价提交成功","success")
              this.send(v)
            }
          } catch (error) {
            this.eventNotifyTost('提示', '网络超时', 'warning')
            this.send(v)
          }

        }
      } else {
        this.send({type:'cancel',flag:false})
      }
    }
  }


  import { Component, Vue, Emit, Prop} from 'vue-property-decorator'
  @Component({
    name:"goodsDetailBar",
  })
  export default class goodsDetailBar extends Vue {
    @Prop(Array) goodsTitleBarList_?: any
    @Prop(Number) divWidth_?: number
    mounted(){
    }
  }
